<template>
	<div>
		<div class="row p-l-15 p-b-10">
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-15">
					<span>{{$t("사용여부")}}</span>
				</div>
				<div class="p-l-5">
					<select class="form-control" ref="enabled" @change="onChangeCombo" v-model="searchValue.enabled">
						<option value="">{{$t('전체')}}</option>
						<option v-for="(data, i) in enabledCodeList" :key="`enabledCode${i}`" :value="data.smallCode">
							{{ data.smallCodeName }}
						</option>
					</select>
				</div>
			</div>
			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-5">
					<span>{{$t("운영코드")}}</span>
				</div>
				<div class="p-l-5">
					<select class="form-control keyword" ref="enabled" @change="onChangeCombo" v-model="searchValue.oprtnCodeYn">
						<option value="">{{$t("전체")}}</option>
						<option v-for="(data, i) in enabledCodeList" :key="`enabledCode${i}`" :value="data.smallCode">
							{{ data.smallCodeName }}
						</option>
					</select>

					<!-- <input :ref="'keyword'" class="form-control" @keydown.enter="onSearch"/> -->
				</div>
			</div>

			<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
				<div class="pt-2 p-l-5">
					<span>{{$t("코드명")}}</span>
				</div>
				<div class="p-l-5">
					<valid-input
						:classList="'form-control'"
						@keydownMethod="onSearch"
						:inputType="'text'"
						:inputValue.sync="searchValue.codeName"
						:rules="rules.COMMON_LARGE_CODE_SEARCH_NAME_RULE"
						:NonStatus="true"
					></valid-input>
				</div>
			</div>

			<div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px' }">
				<button class="form-control" @click="onSearch">{{$t("조회")}}</button>
				<button class="form-control ml-1 mr-1" @click="insertRow" :style="{ minWidth: '100px' }">{{$t("행 추가")}}</button>
				<button class="form-control ml-1 mr-1" @click="save"  >{{$t("저장")}}</button>
				<button class="form-control ml-1 mr-1" @click="remove">{{$t("삭제")}}</button>
				<!-- <button class="form-control" @click="onRemove">삭제</button> -->
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@/api/backEndApi";
	import ValidInput from "@src/components/validate-input/Valid-Input.vue";
	import * as rules from "@src/consts/ruleConsts.js";

	export default {
		name: "PointMgmt",
		props: [],
		components: {
			ValidInput,
		},
		watch: {},
		data() {
			return {
				largeCodeList: null,
				enabledCodeList: [],
				searchValue: {
					enabled: "",
					oprtnCodeYn: "",
					codeName: "",
				},
				rules,
			};
		},
		created() {
			// 처음 진입 시에만 호출
			this.init();
		},
		mounted() {},
		methods: {
			init() {
				// 대분류코드 목록
				this.getLargeCodeList();
				this.getSmallCodeList();
			},
			insertRow() {
				let objDiv = document.getElementsByClassName("pulibcTableClass");
				console.log(objDiv);
				objDiv[0].scrollTop = objDiv[0].scrollHeight + 100;
				this.$emit("insertRow");
			},
			getLargeCodeList() {
				// backEndApi.publicCode.getLargeCodeList().then(({ data }) => {
				//     if(!data.errno){
				//         this.largeCodeList = data;
				//         this.onSearch();
				//     }
				// });

/*           
      ↓↓↓↓↓
*/

				backEndApi.publicCode.getLargeCodeList().then(({ data }) => {
					this.largeCodeList = data;
					this.onSearch();
				});
			},
			async getSmallCodeList() {
				const result = await backEndApi.publicCode.getSmallCodeList("enabled");

				this.enabledCodeList = result.data;
			},

			// Event
			onChangeCombo() {
				this.onSearch();
			},
			onSearch() {
				const keyword = this.searchValue.codeName; // 대분류코드
				const enabled = this.searchValue.enabled; // 사용여부
				const oprtnCodeYn = this.searchValue.oprtnCodeYn; // 운영코드

				var result = this.largeCodeList
					.filter((data) =>
						keyword == "" ? data.largeCodeName == data.largeCodeName : data.largeCodeName.includes(keyword) || keyword == "" ? data.largeCode == data.largeCode : data.largeCode.includes(keyword)
					)
					.filter((data) => (enabled == "" ? data.enabled == data.enabled : data.enabled == enabled))
					.filter((data) => (oprtnCodeYn == "" ? data.oprtnCodeYn == data.oprtnCodeYn : data.oprtnCodeYn == oprtnCodeYn));

				this.$emit("search:data", result);
			},
			reSearch() {
				this.largeCodeList = null;

				this.getLargeCodeList();
			},
			save() {
				this.$emit("save");
			},
			remove() {
				this.$emit("remove");
			},
		},
	};
</script>

<style scoped></style>
