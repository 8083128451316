<template>
		<b-table
			hover
			bordered
			head-variant="dark"
			no-border-collapse
			show-empty
			sticky-header
			ref="selectableTable"
			:class="'text-center pulibcTableClass'"
			:items="items"
			:fields="fields"
			:empty-text="$t('조회된 결과가 없습니다.')"
		>
			<template #empty="scope">
				<h4>{{ scope.emptyText }}</h4>
			</template>

			<!-- <template #cell(selected)="{rowSelected}">
    <template v-if="rowSelected">
      <span aria-hidden="true">&check;</span>
      <span class="sr-only">Selected</span>
    </template>
    <template v-else>
      <span aria-hidden="true">&nbsp;</span>
      <span class="sr-only">Not selected</span>
    </template>
  </template> -->

			<!-- <template #cell(index)="data">
    {{ data.index + 1 }}
  </template> -->

			<template #cell(largeCodeName)="row">
				<div style="min-width: 180px;">
					<valid-input
						:vid="$t('대분류 코드명')"
						:inputType="'text'"
						:inputValue.sync="row.item.largeCodeName"
						:rules="rules.COMMON_LARGE_CODE_NAME_RULE"
						:errorMessage="validMessage.COMMON_LARGE_CODE_NAME_VALID_MESSAGE"
						@inputMethod="onRowEnabledChange(row.item, row.index)"
						style="min-width: 150px;"
					>
					</valid-input>
				</div>
			</template>

			<template #cell(largeCode)="row">
				<div style="width: 180px;">
					<valid-input
						:vid="$t('대분류 코드')"
						:inputType="'text'"
						:inputValue.sync="row.item.largeCode"
						:rules="rules.COMMON_LARGE_CODE_RULE"
						:errorMessage="validMessage.COMMON_LARGE_CODE_VALID_MESSAGE"
						@inputMethod="onRowEnabledChange(row.item, row.index)"
						:formatter="formatter"
						:disabled="row.item.isNew === true ? false : true"
					>
					</valid-input>
				</div>
			</template>

			<template #cell(enabled)="row">
				<select class="form-control" ref="enabled" v-model="row.item.enabled" @change="onRowEnabledChange(row.item, row.index)" :style="{ minWidth: '100px' }">
					<option v-for="(data, i) in enabledCodeList" :key="`enabledCode${i}`" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</template>

			<template #cell(oprtnCodeYn)="row">
				<select class="form-control" ref="enabled" v-model="row.item.oprtnCodeYn" @change="onRowEnabledChange(row.item, row.index)" :style="{ minWidth: '100px' }">
					<option v-for="(data, i) in enabledCodeList" :key="`enabledCode${i}`" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</template>
      
			<template #cell(sortOrder)="row">
				<div style="width: 80px;">
					<valid-input
						:vid="$t('정렬순서')"
						:inputType="'number'"
						:inputValue.sync="row.item.sortOrder"
						:rules="rules.COMMON_LARGE_CODE_SORT_RULE"
            :errorMessage="validMessage.COMMON_LARGE_SORT_ORDER_VALID_MESSAGE"
						@inputMethod="onRowEnabledChange(row.item, row.index)"
					></valid-input>
				</div>
			</template>

			<template #cell(etcInfo1)="row">
				<valid-input
					:vid="$t('기타정보1')"
					:inputType="'text'"
					:inputValue.sync="row.item.etcInfo1"
					:rules="rules.COMMON_LARGE_CODE_ETCINFO_RULE"
					@inputMethod="onRowEnabledChange(row.item, row.index)"
					:NonStatus="true"
				></valid-input>
			</template>

			<template #cell(etcInfo2)="row">
				<valid-input
					:vid="$t('기타정보2')"
					:inputType="'text'"
					:inputValue.sync="row.item.etcInfo2"
					:rules="rules.COMMON_LARGE_CODE_ETCINFO_RULE"
					@inputMethod="onRowEnabledChange(row.item, row.index)"
					:NonStatus="true"
				></valid-input>
			</template>

			<template #cell(etcInfo3)="row">
				<valid-input
					:vid="$t('기타정보3')"
					:inputType="'text'"
					:inputValue.sync="row.item.etcInfo3"
					:rules="rules.COMMON_LARGE_CODE_ETCINFO_RULE"
					@inputMethod="onRowEnabledChange(row.item, row.index)"
					:NonStatus="true"
				></valid-input>
			</template>

			<template #cell(etcInfo4)="row">
				<valid-input
					:vid="$t('기타정보4')"
					:inputType="'text'"
					:inputValue.sync="row.item.etcInfo4"
					:rules="rules.COMMON_LARGE_CODE_ETCINFO_RULE"
					@inputMethod="onRowEnabledChange(row.item, row.index)"
					:NonStatus="true"
				></valid-input>
			</template>

			<template #cell(etcInfo5)="row">
				<valid-input
					:vid="$t('기타정보5')"
					:inputType="'text'"
					:inputValue.sync="row.item.etcInfo5"
					:rules="rules.COMMON_LARGE_CODE_ETCINFO_RULE"
					@inputMethod="onRowEnabledChange(row.item, row.index)"
					:NonStatus="true"
				></valid-input>
			</template>

			<template #cell(rmk)="row">
				<valid-input
					:vid="$t('비고')"
					:inputType="'text'"
					:inputValue.sync="row.item.rmk"
					:rules="rules.COMMON_LARGE_CODE_ETC_RULE"
					@inputMethod="onRowEnabledChange(row.item, row.index)"
					:NonStatus="true"
				></valid-input>
			</template>

			<template #cell(checkbox)="row">
				<b-form-checkbox v-model="checkboxList[row.index]" :style="{ paddingLeft: '2rem' }" @change="onRowDetail(row.item, row.index, false)"></b-form-checkbox>
			</template>
		</b-table>
</template>

<style>
	.b-table-sticky-header {
		max-height: 760px;
	}

	.publicTableClass table tbody tr td {
		vertical-align: middle;
	}
</style>

<script>
	import backEndApi from "@src/api/backEndApi";
	import ValidInput from "@src/components/validate-input/Valid-Input.vue";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";

	export default {
		components: {
			ValidInput,

			// detailCard,,detailBtn
		},
		props: ["data"],
		data() {
			return {
				fields: null,
				items: [],
				emptyText: null,
				enabledCodeList: [],
				selectedRow: [],
				newRowList: [],
				changedRow: [],
				checkboxList: [],
				originData: [],
				saveItems: [],
				rules,
				validMessage,
			};
		},
		watch: {
			data() {
				// this.selectedRow._showDetails =  !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.init();
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			formatter(value) {
				let rule = new RegExp("[\\{\\}\\[\\]\\/?.,;:|\\)*~`!^\\-+┼<>@\\#$%&\\'\"\\\\(\\=ㄱ-ㅎㅏ-ㅣ가-힣]", "gi");

				return value.replace(rule, "");
			},
			init() {
				this.getSmallCodeList();
			},
			onRowEnabledChange(row, index) {
				if (!row.isNew) {
					const compare = this.originData.find((data) => data.largeCode === row.largeCode);
					let isDifferent = false;

					for (let i = 0; i < Object.keys(compare).length; i++) {
						const keyName = Object.keys(compare)[i];
						if (compare[keyName] !== row[keyName]) {
							isDifferent = true;
							break;
						}
					}

					if (isDifferent === true) {
						this.checkboxList[index] = true;
						row.checked = true;
					} else {
						this.checkboxList[index] = false;
						row.checked = false;
					}

					this.onRowDetail(row, index, false);
				}
			},

			onRowDetail(row, index, isRow) {
				console.log(row);
				if (isRow !== false) {
					this.checkboxList[index] = !this.checkboxList[index];
				}

				if (this.checkboxList[index] === true) {
					row.checked = true;
					const isSelectedIndex = this.selectedRow.findIndex((v) => v.largeCode === row.largeCode);

					if (isSelectedIndex === -1) {
						this.selectedRow.push(row);
					} else {
						this.selectedRow[isSelectedIndex] = row;
					}
				} else {
					row.checked = false;
					const index = this.selectedRow.findIndex((data) => data.largeCode === row.largeCode);
					this.selectedRow.splice(index, 1);
				}

				this.$emit("getSelectedRow", this.selectedRow);

				// console.log(row, index, this.selectedRow);
			},
			resetRowSelect() {
				this.setItems();
			},
			async getSmallCodeList() {
				const result = await backEndApi.publicCode.getSmallCodeList("enabled");

				this.enabledCodeList = result.data;
			},
			clearSeclected() {
				this.$refs.selectableTable.clearSelected();
			},
			setFields() {
				this.fields = [
					{ key: "checkbox", label: "" },
					{ key: "largeCode", label: global.xe.$t("대분류 코드") },
					{ key: "largeCodeName", label: global.xe.$t("대분류 코드명") },
					{ key: "enabled", label: global.xe.$t("사용여부") },
					{ key: "oprtnCodeYn", label: global.xe.$t("운영코드") },
					{ key: "sortOrder", label: global.xe.$t("정렬 순서") },
					{ key: "etcInfo1", label: global.xe.$t("기타정보")+ "1" },
					{ key: "etcInfo2", label: global.xe.$t("기타정보")+ "2" },
					{ key: "etcInfo3", label: global.xe.$t("기타정보")+ "3" },
					{ key: "etcInfo4", label: global.xe.$t("기타정보")+ "4" },
					{ key: "etcInfo5", label: global.xe.$t("기타정보")+ "5" },
					{ key: "rmk", label: global.xe.$t("비고") },
				];
			},
			setItems() {
				console.log(" code ----- set ---- item");
				if (this.data === null) return;

				this.checkboxList = [];
				this.selectedRow = [];
				this.items = this.data;
				this.originData = JSON.parse(JSON.stringify(this.items));

				for (let i = 0; i < this.items.length; i++) {
					if (this.items[i].isNew === true) {
						this.checkboxList.push(true);
						this.selectedRow.push(this.items[i]);
					} else {
						if (this.items[i].checked === true) {
							this.checkboxList.push(true);
							this.selectedRow.push(this.items[i]);
						} else {
							this.checkboxList.push(false);
						}
					}
				}

				this.$emit("getSelectedRow", this.selectedRow);
			},
			onModify() {
				this.$refs.detail.onModify();
			},
			onRemove() {
				this.$refs.detail.onRemove();
			},
			onReset() {
				this.$refs.detail.onReset();
			},
			reSearch() {
				this.$emit("reSearch");
			},
		},
	};
</script>
